import React, { useState, useEffect } from 'react';
import { useSearchParams, shouldFetchApi, shouldSetSearchParams } from 'hooks/useCustomSearchParams';

import moment from 'moment';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useDispatch } from 'hooks/useCustomDispatch';
import { useTranslation } from 'react-i18next';

import { bookingList, fetchBookingsForCSV, resetBookingProvider } from 'providers/BookingProvider/actions';

import { Button, Input, Checkbox, Divider, Popover, Table, Card, Row, Col, DatePicker, Form, Tag } from 'antd';
import { UserOutlined, SearchOutlined, ExportOutlined } from '@ant-design/icons';

import BookingDetailModal from 'components/Desktop/Booking/booking-detail.modal';
import PageLayout from 'components/Desktop/Layout/PageLayout';

import './styles.less';
import { formatYen } from 'utils/stringFormat';
import { getLanguages } from 'utils/lang';
import { BOOKING_SEARCH_STATUS, BOOKING_STATUS, LIMIT } from 'utils/constants';
import { getSalonSalesAmount } from 'utils/sales';
import { HANDLE_EXPORT_CSV_FILE } from 'utils/exportCSVHelper';
import Avatar from 'components/Desktop/Avatar';

const { RangePicker } = DatePicker;
const CSVStructJA = {
  予約番号: 'id',
  施術日: 'bookingTime',
  カスタマー名: 'customer',
  スタッフ: 'staff[0].name',
  売上: 'price',
  ステータス: 'status',
};

const CSVStructEN = {
  'Booking ID': 'id',
  'Treatment date': 'bookingTime',
  'Customer name': 'customer',
  Staff: 'staff[0].name',
  'Total(Salon sales)': 'price',
  Status: 'status',
};
const Bookings = () => {
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [bookingDetailModal, setBookingDetailModal] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);

  // <URLSearchParams>
  const [searchParamsValues, handleSetSearchParams] = useSearchParams();
  const { keyword, from, to, sort = 'startTime desc' } = searchParamsValues;
  const page = parseInt(searchParamsValues.page);
  const targetSystemStatus = searchParamsValues.status;
  // </URLSearchParams>

  const {
    bookings = {},
    loading,
  } = useSelector((state) => state.booking);
  const locale = useSelector((state) => state.i18n.locale);
  // The CSV file name and the data depends on the search result list
  const csvPrefixNameDefault = locale === 'en' ? 'booking_list' : '予約リスト';
  const csvFileName = (from !== undefined) ? `${csvPrefixNameDefault}_${from}_${to}.csv` : `${csvPrefixNameDefault}.csv`;
  const dataSource = _.get(bookings, 'data')?.map((b) => ({ ...b, key: b.id })) || [];
  const totalPage = _.get(bookings, 'pagination.total');

  const getBookings = () => {
    const params = {
      page,
      limit: LIMIT,
      sort,
    };
    if (from) {
      params.from = moment(from, 'YYYY-MM-DD').startOf('day').toISOString();
    }
    if (to) {
      params.to = moment(to, 'YYYY-MM-DD').endOf('day').toISOString();
    }
    if (keyword) {
      params.keyword = keyword;
    }
    if (targetSystemStatus) {
      params.targetSystemStatus = targetSystemStatus;
    } else {
      params.targetSystemStatus = BOOKING_SEARCH_STATUS.join(',');
    }

    dispatch(bookingList(params));
  };
  const handleExportCSV = () => {
    setExportLoading(true);
    const params = {
      page: 1,
      limit: totalPage,
      sort,
    };
    if (from) {
      params.from = moment(from, 'YYYY-MM-DD').startOf('day').toISOString();
    }
    if (to) {
      params.to = moment(to, 'YYYY-MM-DD').endOf('day').toISOString();
    }
    if (keyword) {
      params.keyword = keyword;
    }
    if (targetSystemStatus) {
      params.targetSystemStatus = targetSystemStatus;
    } else {
      params.targetSystemStatus = BOOKING_SEARCH_STATUS.join(',');
    }
    dispatch(fetchBookingsForCSV(params))
      .then((result) => {
        const dataList = _.get(result, 'bookings.data');
        setExportLoading(false);
        HANDLE_EXPORT_CSV_FILE.handleExportAllBookingListCSV(dataList, CSVStructEN, CSVStructJA, csvFileName, locale, lang);
      })
      .catch((error) => {
        setExportLoading(false);
        console.log('Oops!', error);
      });
  };

  useEffect(() => {
    if (shouldFetchApi(searchParamsValues)) {
      getBookings();
    }

    const dates = (from && to) ? [moment(from, 'YYYY-MM-DD'), moment(to, 'YYYY-MM-DD')] : null;
    form.setFieldsValue({
      keyword,
      dates,
    });
  }, [keyword, from, to, sort, page, targetSystemStatus]);

  useEffect(() => {
    const defaultSearch = {
      page: 1,
      sort,
    };

    if (shouldSetSearchParams(searchParamsValues)) {
      handleSetSearchParams(defaultSearch, true);
    }
  }, [searchParamsValues]);
  // Reset booking provider
  useEffect(() => {
    return () => {
      dispatch(resetBookingProvider());
    };
  }, []);

  const handleClearSearchBooking = (e) => {
    if (e.target.value === '') { // input is cleared.
      const search = {
        ...searchParamsValues,
      };
      delete search.keyword;
      handleSetSearchParams(search);
    }
  };
  const handleClearRangeDate = (dateValue) => {
    if (!dateValue) {
      const search = {
        ...searchParamsValues,
      };
      delete search.from;
      delete search.to;
      handleSetSearchParams(search);
    }
  };

  const columns = [
    {
      title: lang.bookingID,
      dataIndex: 'sourceId',
      key: 'sourceId',
      width: '10%',
    },
    {
      title: lang.treatmentDate,
      dataIndex: 'startTime',
      defaultSortOrder: 'descend',
      key: 'startTime',
      width: '12%',
      align: 'center',
      render: function renderItem (record) {
        const startTime = moment(record);
        return (
          <div>
            <div style={{ whiteSpace: 'nowrap' }}>{startTime.format(locale === 'en' ? 'YYYY-MM-DD' : 'YYYY年M月D日')}</div>
            <div>{startTime.format('HH:mm')}</div>
          </div>
        );
      },
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: sort.split(' ')[1] + 'end',
    },
    {
      title: lang.customerName,
      dataIndex: 'customer',
      key: 'customer',
      width: '15%',
      render: function renderItem (record) {
        return (
          <div >
            <div>{record.name}</div>
            <div>{record.phonetic ? `(${record.phonetic})` : ''}</div>
          </div>
        );
      },
    },
    {
      title: lang.staff,
      dataIndex: 'orders',
      key: 'orders',
      width: '12%',
      render: (record) => {
        const staffs = record.map(item => ({
          id: item.staff.id,
          name: _.get(item, 'staff.connections[0].data.name') || _.get(item, 'staff.name'),
          avatar: _.get(item, 'staff.connections[0].data.avatarLink') || _.get(item, 'staff.avatar'),
          username: _.get(item, 'staff.connections[0].data.username'),
        }));

        if (_.uniqBy(staffs, 'id').length >= 3) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {staffs[0].avatar !== undefined
                ? <Avatar src={staffs[0].avatar} style={{ marginRight: 8 }}/>
                : <Avatar icon={<UserOutlined />} style={{ marginRight: 8 }}/>
              }
              {staffs[0].name},
              {staffs[1].avatar !== undefined
                ? <Avatar src={staffs[1].avatar} style={{ marginRight: 8, marginLeft: 4 }}/>
                : <Avatar icon={<UserOutlined />} style={{ marginRight: 8, marginLeft: 4 }}/>
              }
              {staffs[1].name},
              <Popover content={(
                <div>
                  {_.uniqBy(staffs, 'id').map((staff, index) => {
                    if (index >= 2) return <p>{staff.name}</p>;
                  })}
                </div>
              )}>
                <Button type="link" style={{ marginLeft: -8 }}>+ {_.uniqBy(staffs, 'id').length - 2} more</Button>
              </Popover>
            </div>
          );
        } else {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {_.uniqBy(staffs, 'id').length === 1 && (
                <>
                  {staffs[0].avatar !== undefined
                    ? <Avatar src={staffs[0].avatar} style={{ marginRight: 8 }}/>
                    : <Avatar icon={<UserOutlined />} style={{ marginRight: 8 }}/>
                  }
                  <div>
                    <div>{_.uniqBy(staffs, 'id')[0]?.username}</div>
                    <div>{_.uniqBy(staffs, 'id')[0]?.name}</div>
                  </div>
                </>
              )}

              {_.uniqBy(staffs, 'id').length === 2 && (
                <>
                  {staffs[0].avatar !== undefined
                    ? <Avatar src={staffs[0].avatar} style={{ marginRight: 8 }}/>
                    : <Avatar icon={<UserOutlined />} style={{ marginRight: 8 }}/>
                  }
                  {_.uniqBy(staffs, 'id')[0]?.name},
                  {staffs[1].avatar !== undefined
                    ? <Avatar src={staffs[1].avatar} style={{ marginRight: 8, marginLeft: 4 }}/>
                    : <Avatar icon={<UserOutlined />} style={{ marginRight: 8, marginLeft: 4 }}/>
                  }
                  {_.uniqBy(staffs, 'id')[1].name}
                </>
              )}
            </div>
          );
        }
      },
    },
    {
      title: lang.total,
      width: '12%',
      dataIndex: 'totalPrice',
      key: 'totalSales',
      render: function renderTotalSales (value, record, index) {
        const amount = getSalonSalesAmount(record);

        return <div>{formatYen(amount)}</div>;
      },
    },
    {
      title: lang.bookingConfirmedDate,
      key: 'extraInfo',
      width: '12%',
      align: 'center',
      render: function renderItem (record) {
        const confirmedDate = record?.extraInfo?.confirmedDate || record?.createdAt;
        return (
          <div>
            <div style={{ whiteSpace: 'nowrap' }}>{moment(confirmedDate).format(locale === 'en' ? 'YYYY-MM-DD' : 'YYYY年M月D日')}</div>
            <div>{moment(confirmedDate).format('HH:mm')}</div>
          </div>
        );
      },
    },
    {
      title: lang.status,
      dataIndex: 'notes',
      key: 'status',
      alignItems: 'center',
      width: '12%',
      render: function renderItem (record) {
        const status = record.filter(status => status.type === 'targetSystemStatus')[0]?.value.toUpperCase();

        if (BOOKING_STATUS[status]) {
          return <Tag color={BOOKING_STATUS[status]?.color}>{lang[BOOKING_STATUS[status]?.langKey]}</Tag>;
        }

        return <Tag>{status}</Tag>;
      },
      filteredValue: targetSystemStatus ? targetSystemStatus.split(',') : null,
      filterDropdown: function renderItem ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) {
        return (
          <div style={{ paddingTop: 10, paddingBottom: 10 }}>
            <Checkbox.Group
              style={{ width: '100%' }}
              value={selectedKeys}
              onChange={(checkedValues) => {
                setSelectedKeys(checkedValues);
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {Object.keys(BOOKING_STATUS).filter(s => s !== 'DELETED').map((status) => (
                  <Checkbox value={status} key={status} style={{ marginLeft: 8, marginBottom: 8 }}>
                    <Tag color={BOOKING_STATUS[status]?.color}>{lang[BOOKING_STATUS[status]?.langKey]}</Tag>
                  </Checkbox>
                ))}
              </div>
              <Divider style={{ marginBottom: 10, marginTop: 5 }} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginLeft: 2,
                  marginRight: 10,
                }}
              >
                <Button
                  type="link"
                  size={'small'}
                  onClick={() => {
                    clearFilters(); // Must call this function before
                    const search = {
                      ...searchParamsValues,
                      page: 1,
                    };
                    delete search.status;
                    handleSetSearchParams(search);
                  }}
                >
                  Reset
                </Button>
                <Button
                  type="primary"
                  size={'small'}
                  onClick={() => {
                    confirm(); // Must call this function before
                    const status = selectedKeys.join(',');
                    const search = {
                      ...searchParamsValues,
                      page: 1,
                      status,
                    };
                    if (selectedKeys.length === 0) {
                      delete search.status;
                    }
                    handleSetSearchParams(search);
                  }}
                >
                  OK
                </Button>
              </div>
            </Checkbox.Group>
          </div>
        );
      },
    },
  ];

  const routes = [
    {
      path: '/mysalon',
      breadcrumbName: lang.mySalon,
    },
    {
      path: '/bookings',
      breadcrumbName: lang.searchBookings,
    },
  ];

  return (
    <PageLayout ghost={false} title={lang.searchBookings} routes={routes}>
      <div className="bookings-page">
        <Card style={{ marginBottom: 16 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col span={18}>
              <Form
                form={form}
                layout="inline"
                onFinish={(values) => {
                  const { dates } = values;
                  const search = {
                    ...searchParamsValues,
                    page: 1,
                  };
                  const keywordValue = values.keyword;
                  if (keywordValue && keywordValue.trim()) {
                    search.keyword = keywordValue.trim();
                  } else {
                    delete search.keyword;
                  }
                  if (dates) {
                    search.from = dates[0].format('YYYY-MM-DD');
                    search.to = dates[1].format('YYYY-MM-DD');
                  } else {
                    delete search.from;
                    delete search.to;
                  }
                  handleSetSearchParams(search);
                }}
              >
                <Form.Item name="keyword">
                  <Input
                    placeholder={lang.searchBookingPlaceholder}
                    style={{ width: 200 }}
                    allowClear
                    onChange={(e) => handleClearSearchBooking(e)}
                  />
                </Form.Item>
                <Form.Item name="dates">
                  <RangePicker
                    placeholder={[lang.fromDate, lang.toDate]}
                    allowClear
                    onChange={(e) => handleClearRangeDate(e)}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                    {lang.search}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col span={6} >
              <div style={{ textAlign: 'right' }}>
                <Button
                  type="primary"
                  onClick={() => handleExportCSV()}
                  icon={<ExportOutlined />}
                  loading={exportLoading}
                  disabled={dataSource.length === 0}
                >
                  {lang.exportCSV}
                </Button>
              </div>
            </Col>
          </Row>
        </Card>
        <Card style={{ marginBottom: 18 }}>
          <Table
            loading={loading}
            bordered
            columns={columns}
            dataSource={dataSource}
            onChange={(pagination, filters, sorter) => {
              const search = {
                ...searchParamsValues,
                sort: `${sorter.columnKey} ${sorter.order?.replace('end', '')}`,
                page: pagination.current,
              };
              handleSetSearchParams(search);
            }}
            pagination={{
              total: totalPage,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
              pageSize: LIMIT,
              current: page,
              showSizeChanger: false,
              onChange: () => window.scrollTo(0, 0),
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  const bookingId = _.get(record, 'id');
                  setBookingDetailModal(bookingId);
                },
              };
            }}
            scroll={{ x: 920 }}
          />
        </Card>

        <BookingDetailModal
          bookingId={bookingDetailModal}
          closeBookingDetailModal={() => setBookingDetailModal(null)}
          onCallbackBookingDetailModal={getBookings}
        />
      </div>
    </PageLayout>
  );
};

export default Bookings;
