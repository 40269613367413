// Libraries
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Button, Table, Row, Col, Modal, Spin, Badge, Collapse, Empty, Tag, Radio } from 'antd';
import { MenuOutlined, PlusOutlined, ExclamationCircleOutlined, PictureOutlined } from '@ant-design/icons';
// Customs
import { useDispatch } from 'hooks/useCustomDispatch';
// Providers
import { categoryList } from 'providers/CategoryProvider/actions';
import { serviceDelete, serviceOrder, serviceUpdate } from 'providers/ServiceProvider/actions';

import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
// Components
import ServiceManageForm from 'components/Desktop/Services/ServiceManageForm';
import PageLayout from 'components/Desktop/Layout/PageLayout';
import Avatar from 'components/Desktop/Avatar';
// Utils
import { formatYen } from 'utils/stringFormat';
import { getLanguages } from 'utils/lang';
import { MENU_REPEAT_TYPE } from 'utils/constants';
import './styles.less';

const { Panel } = Collapse;

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));

const SortableCategoryItem = sortableContainer(
  ({ value, setManageServiceModal, updateServiceStatus, confirmServiceDelete, setServiceOrderLoading }) => {
    const { t } = useTranslation();
    const lang = getLanguages(t);
    const dispatch = useDispatch();
    const dataSource = value?.services.map((item, index) => {
      const itemNew = { ...item, index, key: item.id };
      return itemNew;
    });
    const columns = [
      {
        title: 'Sort',
        dataIndex: 'sort',
        width: 30,
        className: 'drag-visible',
        render: function renderItem () {
          return (
            <div style={{ paddingLeft: 45 }}>
              <DragHandle />
            </div>
          );
        },
      },
      {
        title: 'Photo',
        width: 40,
        className: 'drag-visible',
        render: function renderItem (record) {
          return (
            <div>
              {record.photo ? (
                <Avatar shape="square" size="large" src={record.photo} />
              ) : (
                <Avatar shape="square" size="large" icon={<PictureOutlined />} />
              )}
            </div>
          );
        },
      },
      {
        title: 'Repeat Type',
        width: 30,
        className: 'drag-visible',
        render: function renderItem (record) {
          const menuRepeatType = record?.extraInfo?.repeatType;
          return (
            <div>
              {record?.extraInfo?.repeatType && (
                <Tag color={'cyan'}>{lang[MENU_REPEAT_TYPE[menuRepeatType]?.langText]}</Tag>
              )}
            </div>
          );
        },
      },
      {
        title: 'Name',
        className: 'drag-visible',
        render: function renderItem (record) {
          return (
            <div>
              {record.active === true ? (
                <span>{record.name}</span>
              ) : (
                <span>
                  {record.name} <Badge count={lang.disabled} style={{ backgroundColor: 'gray' }} />
                </span>
              )}
            </div>
          );
        },
      },
      {
        title: 'Duration',
        width: '15%',
        render: function renderItem (record) {
          return (
            <div>
              <span>
                {record.duration} {lang.min}
              </span>
            </div>
          );
        },
      },
      {
        title: 'Price',
        width: '15%',
        render: function renderItem (record) {
          return (
            <div>
              <span>{formatYen(record.price)}</span>
            </div>
          );
        },
      },
      {
        title: '',
        width: '3%',
        render: function renderItem (record) {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <Button danger type="link" onClick={() => confirmServiceDelete(record.key)}>
                {lang.delete}
              </Button>
              <Button type="link" onClick={() => updateServiceStatus(record)}>
                {record.active ? lang.disable : lang.enable}
              </Button>
              <Button
                type="link"
                onClick={() =>
                  setManageServiceModal({ service: record, title: lang.updateMenu })
                }
              >
                {lang.edit}
              </Button>
            </div>
          );
        },
      },
    ];

    const SortableItem = sortableElement((props) => <tr {...props} />);
    const SortableContainer = sortableContainer((props) => <tbody {...props} />);
    const DragableBodyRow = ({ ...restProps }) => {
      return <SortableItem index={restProps['data-row-key']} {...restProps} />;
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
        setServiceOrderLoading(true);
        dispatch(serviceOrder({ dataSource, oldIndex, newIndex }))
          .then(() => setServiceOrderLoading(false));
      }
    };

    const DraggableContainer = (props) => (
      <SortableContainer
        useDragHandle
        helperClass="row-dragging"
        onSortEnd={onSortEnd}
        {...props}
      />
    );

    return (
      <div>
        {dataSource.length > 0 && (
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            justify="space-between"
          >
            <Col className="gutter-row" span={24}>
              <Table
                pagination={false}
                showHeader={false}
                dataSource={dataSource}
                columns={columns}
                rowKey="index"
                components={{
                  body: {
                    wrapper: DraggableContainer,
                    row: DragableBodyRow,
                  },
                }}
              />
            </Col>
          </Row>
        )}
      </div>
    );
  },
);

const CategoryItem = ({
  item,
  index,
  setManageServiceModal,
  updateServiceStatus,
  confirmServiceDelete,
  setServiceOrderLoading,
}) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const servicesLength = item.services?.length;
  // Init collapse state
  const [activeKey, setActiveKey] = useState(servicesLength ? [item.id] : []);

  useEffect(() => {
    // Case remove service: category has no service and collapse is opened => close collapse
    if (servicesLength === 0 && activeKey.length === 1) {
      setActiveKey([]);
    }
    // Case add service: category has service and collapse is closed => open collapse
    if (servicesLength > 0 && activeKey.length === 0) {
      setActiveKey([item.id]);
    }
  }, [servicesLength]);

  return (
    <Collapse
      style={{ marginBottom: '16px' }}
      activeKey={activeKey} // Collapse cateogry with no service
      expandIconPosition="right"
      onChange={(key) => {
        setActiveKey(key);
      }}
    >
      <Panel
        key={item.id}
        header={
          <div style={{ fontWeight: 'bold' }}>
            {item.name}{' '}
            {!item.active && (
              <Badge count={'Inactive'} style={{ backgroundColor: 'gray' }} />
            )}
          </div>
        }
      >
        {!item.services?.length ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={lang.emptyStateServicesMessage}
          />
        ) : (
          <SortableCategoryItem
            index={index}
            value={item}
            setManageServiceModal={setManageServiceModal}
            updateServiceStatus={updateServiceStatus}
            confirmServiceDelete={confirmServiceDelete}
            setServiceOrderLoading={setServiceOrderLoading}
          />
        )}
      </Panel>
    </Collapse>
  );
};

CategoryItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  setManageServiceModal: PropTypes.func,
  updateServiceStatus: PropTypes.func,
  confirmServiceDelete: PropTypes.func,
  setServiceOrderLoading: PropTypes.func,
};

const CategoryList = ({
  items,
  setManageServiceModal,
  updateServiceStatus,
  confirmServiceDelete,
  setServiceOrderLoading,
}) => {
  return (
    <>
      {items &&
        items.map((item, index) => (
          <CategoryItem
            key={item.id}
            item={item}
            index={index}
            setManageServiceModal={setManageServiceModal}
            updateServiceStatus={updateServiceStatus}
            confirmServiceDelete={confirmServiceDelete}
            setServiceOrderLoading={setServiceOrderLoading}
          />
        ))}
    </>
  );
};

CategoryList.propTypes = {
  items: PropTypes.array,
  setManageServiceModal: PropTypes.func,
  updateServiceStatus: PropTypes.func,
  confirmServiceDelete: PropTypes.func,
  setServiceOrderLoading: PropTypes.func,
};

const Services = () => {
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const dispatch = useDispatch();

  const { categories = [], loading } = useSelector((state) => state.category);

  useEffect(() => {
    dispatch(categoryList());
  }, []);

  const [manageServiceModal, setManageServiceModal] = useState(null);
  const [serviceOrderLoading, setServiceOrderLoading] = useState(false);

  const confirmServiceDelete = (id) => {
    Modal.confirm({
      title: lang.confirm,
      icon: <ExclamationCircleOutlined />,
      content: lang.confirmDeleteService,
      okText: lang.yes,
      cancelText: lang.no,
      onOk: () => {
        dispatch(serviceDelete({ id }));
      },
      onCancel: () => console.log('Close'),
    });
  };

  const updateServiceStatus = (record) => {
    Modal.confirm({
      title: lang.confirm,
      icon: <ExclamationCircleOutlined />,
      content: `${lang.updateServiceStatusModalContentHead}${
        record.active ? lang.disable : lang.enable
      }${lang.updateServiceStatusModalContentTail}`,
      okText: lang.yes,
      cancelText: lang.no,
      onOk: () => {
        record.active = !record.active;
        dispatch(serviceUpdate({ id: record.id, values: record }));
      },
      onCancel: () => console.log('Close'),
    });
  };

  const routes = [
    {
      path: '/mysalon',
      breadcrumbName: lang.mySalon,
    },
    {
      path: '/menu-settings',
      breadcrumbName: lang.menuSettings,
    },
  ];

  const AddServiceButton = ({ key }) => (
    <Button
      loading={loading}
      key={key}
      type="primary"
      icon={<PlusOutlined />}
      id="add-menu-btn"
      onClick={() => {
        setManageServiceModal({ title: lang.addMenu });
      }}
    >
      {lang.addMenu}
    </Button>
  );

  const [serviceFilter, setServiceFilter] = useState('ALL');

  const filterOptions = [
    { label: lang.all, value: 'ALL' },
    { label: lang.nail, value: 'NAILIST' },
    { label: lang.eye, value: 'EYELIST' },
  ];

  const filteredCategories = categories.filter(category =>
    serviceFilter === 'ALL' || category.serviceRole === serviceFilter,
  );

  return (
    <PageLayout
      ghost={false}
      title={lang.menuSettings}
      extraHeader={[<AddServiceButton key="add-service-button" />]}
      routes={routes}
    >
      <Spin spinning={loading || serviceOrderLoading}>
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Radio.Group
              onChange={(e) => setServiceFilter(e.target.value)}
              value={serviceFilter}
            >
              {filterOptions.map(option => (
                <Radio key={option.value} value={option.value}>
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
          </Col>
          <Col span={24}>
            {filteredCategories.length ? (
              <CategoryList
                items={filteredCategories}
                setManageServiceModal={setManageServiceModal}
                updateServiceStatus={updateServiceStatus}
                confirmServiceDelete={confirmServiceDelete}
                setServiceOrderLoading={setServiceOrderLoading}
              />
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="•••" />
            )}
          </Col>
        </Row>
      </Spin>

      <Modal
        visible={!!manageServiceModal}
        title={_.get(manageServiceModal, 'title', '')}
        onCancel={() => setManageServiceModal(null)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        maskClosable={false}
      >
        <ServiceManageForm
          service={_.get(manageServiceModal, 'service', {})}
          onCancel={() => setManageServiceModal(null)}
        />
      </Modal>
    </PageLayout>
  );
};

export default Services;
