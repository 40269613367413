// Libraries
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Modal, Button, Checkbox, Tabs, Card, Row, Col, Spin, Collapse, List, Tag } from 'antd';
import { PictureOutlined, ExclamationCircleOutlined, StarFilled, EditOutlined, DeleteOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';

// Customs
import { useDispatch } from 'hooks/useCustomDispatch';
import { useSearchParams } from 'hooks/useCustomSearchParams';

// Components
import StaffUpdateForm from 'components/Desktop/Staff/StaffUpdateForm';
import PageLayout from 'components/Desktop/Layout/PageLayout';
import Avatar from 'components/Desktop/Avatar';

// Providers
import {
  staffDetailCategoriesServices,
  staffDetailAddServices,
  staffDetail,
  staffSendInvite,
  staffCancelInvite,
  removeStaff,
} from 'providers/StaffProvider/actions';

// Utils
import { getLanguages } from 'utils/lang';
import { formatYen } from 'utils/stringFormat';
import { MENU_REPEAT_TYPE, PRE_RANDOM_NUM, STAFF_STATUS } from 'utils/constants';
import { getValueFromRandomInfor } from 'utils/common';
import './styles.less';
import { SALON_HIDDEN_TYPES } from 'utils/SharedSalonEnv';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const StaffDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  // <URLSearchParams>
  const [searchParamsValues, handleSetSearchParams] = useSearchParams();
  const { tab = 'personal-info' } = searchParamsValues;
  // </URLSearchParams>

  // Language
  const { t } = useTranslation();
  const lang = getLanguages(t);
  // State
  const [services, setServices] = useState([]);
  const [isStaffUpdateModal, setIsStaffUpdateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [staff, setStaff] = useState();
  const [categories, setCategories] = useState([]);
  const [recommendService, setRecommendService] = useState();
  const staffStatus = staff?.connections[0]?.status;

  const { salon } = useSelector(state => state.salon);
  const isConnectedBM = salon?.thirdParties[0]?.code === 'BM';
  const isConnectedCS = salon?.thirdParties[0]?.code === 'CS';
  const isHiddenFeature = SALON_HIDDEN_TYPES.includes(salon?.salonType);

  // Constants
  // Staff connections status { 0: Waiting ,1: Connected, 2: Rejected, 3:Disconnected, 4: Expired, 5: Canceled }
  const statusCanRemoveStaff = [1, 2, 4, 5];
  const removeStaffWithoutStatus = staff?.connections || [];
  const resendInvitationBtn = [2, 4];
  const cancelInvitationBtn = [0];
  const staffConnected = [1];
  const canRemoveStaff = statusCanRemoveStaff.includes(staffStatus) || removeStaffWithoutStatus.length === 0;

  // Service off can not set recommend
  const serviceOff = [];
  categories.forEach(item => {
    if (!_.isEmpty(item.services) && (item.type === 'HAND_OFF' || item.type === 'FOOT_OFF')) {
      item.services.forEach(service => {
        if (service.active === true) {
          serviceOff.push(service.id);
        }
      });
    }
  });

  useEffect(() => {
    setRecommendService(undefined);
    setLoading(true);
    let staffData;
    dispatch(staffDetail({ staffId: id }))
      .then(result => {
        setStaff(result.data);
        staffData = result.data;
        const services = result.data?.services.map(item => item.id);
        setServices(services);
        setLoading(false);
        _.get(result, 'data.services').forEach(service => {
          if (service.isRecommended === 1 && service.active === true) {
            setRecommendService(service.id);
          }
        });
        return dispatch(staffDetailCategoriesServices());
      })
      .then(result => {
        const filteredCategories = result.filter(category =>
          category.serviceRole === staffData.serviceRole,
        );
        setCategories(filteredCategories);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, [tab]);

  // Cancel invitation
  const onCancel = (staffId) => {
    dispatch(staffCancelInvite({ staffId }))
      .then(() => {
        dispatch(staffDetail({ staffId }))
          .then(result => {
            setStaff(result.data);
          });
      })
      .catch(err => console.log(err));
  };

  // Resend invitation
  const onResend = (staffId, identity) => {
    dispatch(staffSendInvite({ staffId, identity }))
      .then(() => {
        dispatch(staffDetail({ staffId }))
          .then(result => {
            setStaff(result.data);
          });
      })
      .catch(err => console.log(err));
  };

  // Remove staff
  const onRemove = (staffId) => {
    dispatch(removeStaff({ staffId }))
      .then(() => {
        navigate('/mysalon/staff-member');
      })
      .catch(err => console.log(err));
  };

  const changeRecommendService = value => {
    if (services.includes(value) && !recommendService) {
      setRecommendService(value);
    } else if (recommendService === value) {
      setRecommendService(undefined);
    }
  };

  const handleAddServiceToStaff = () => {
    setLoading(true);
    dispatch(staffDetailAddServices({
      staffId: staff?.id,
      services,
      recommend: recommendService,
    }))
      .then(() => {
        // Refresh
        setLoading(false);
        dispatch(staffDetail({ staffId: staff?.id }))
          .then(result => {
            setStaff(result?.data);
            const services = result.data?.services.map(item => item.id);
            setServices(services);
          });
      });
  };

  // List menu in each category
  const renderMenuCell = (service) => {
    const menuRepeatType = service?.extraInfo?.repeatType;
    return (
      <List.Item>
        <Col span={24} key={service.id}>
          <Row span ={24} className="service-item">
            <Col span={3}>
              <Checkbox value={service.id}>
                <div className="service-item-list">
                  <span className="service-photo">
                    { service.photo
                      ? <Avatar shape="square" size="large" src={service.photo} />
                      : <Avatar shape="square" size="large" icon={<PictureOutlined />} />
                    }
                  </span>
                </div>
              </Checkbox>
            </Col>
            <Col span ={2}>
              <div>
                {
                  menuRepeatType &&
                  <Tag color={'cyan'}>{lang[MENU_REPEAT_TYPE[menuRepeatType]?.langText]}</Tag>
                }
              </div>
            </Col>
            <Col span ={5}>
              <div className="service-name">{service.name}</div>
            </Col>
            <Col span={2}>
              <div className="service-duration">
                {service.duration}
                {lang.min}
              </div>

            </Col>
            <Col span={2}>
              <div >
                {formatYen(service.price)}
              </div>
            </Col>
            <Col span={4} style={{ textAlign: 'right' }}>
              {(recommendService === service.id && services.includes(recommendService)) &&
                <span className="service-recommended">
                  <span className='service-recommended-icon'><StarFilled /></span>
                  <span className="service-recommended-text">{lang.recommended}</span>
                </span>
              }
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}>
              {
                (services.includes(service.id) && !serviceOff.includes(service.id)) &&
                < Button
                  type="link"
                  danger={ recommendService === service.id }
                  disabled={recommendService && recommendService !== service.id }
                  onClick={() => { changeRecommendService(service.id); }}
                >
                  { service.id === recommendService ? lang.removeRecommend : lang.setRecommend }
                </Button>
              }
            </Col>
          </Row>
        </Col>
      </List.Item>
    );
  };

  // categories list
  const renderCategories = (services = [], category) => {
    if (services.filter(i => i.active === true)?.length > 0) {
      return (
        <Col xs={{ span: 24 }} xl={{ span: 20, offset: 2 }} xxl={{ span: 14, offset: 5 }}>
          <Collapse
            style={{ marginBottom: '16px' }}
            bordered={true}
            defaultActiveKey={category.id}
            expandIconPosition="right"
          >
            <Panel
              key={category.id}
              header={
                <div style={{ fontWeight: 'bold' }}>
                  {category.name}
                </div>
              }

            >
              <List
                itemLayout="horizontal"
                dataSource={services.filter(i => i.active === true).sort((a, b) => a.order - b.order)}
                renderItem={renderMenuCell}
              />
            </Panel>
          </Collapse>
        </Col>
      );
    }
  };

  const renderEditProfileButton = () => {
    return (
      <Button
        type="primary"
        icon={<EditOutlined />}
        onClick={() => setIsStaffUpdateModal(true)}
        disabled={!(staffStatus === 1)} // Salon Can edit profile when nailist connected
      >
        {lang.editProfile}
      </Button>
    );
  };

  const renderRemoveStaffButton = () => {
    return (
      <Button
        style={{ marginRight: 16 }}
        danger
        icon={<DeleteOutlined />}
        onClick={() => {
          Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: lang.confirmDeleteStaffTitle,
            content: staffConnected.includes(staffStatus) && lang.confirmDeleteStaffContent,
            okType: 'danger',
            onOk () {
              onRemove(staff?.connections[0]?.staffId || staff?.id);
            },
            onCancel () {},
          });
        }}
      >
        {lang.removeStaff}
      </Button>
    );
  };

  const renderCancelInvitationButton = () => {
    return (
      <Button
        style={{ marginRight: 16 }}
        danger
        onClick={() => {
          Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            content: lang.confirmCancel,
            onOk () {
              onCancel(staff?.connections[0]?.staffId);
            },
            onCancel () {},
          });
        }}
      >
        {lang.cancelInvitation}
      </Button>
    );
  };

  const renderResendInvitationButton = () => {
    return (
      <Button
        style={{ marginRight: 16 }}
        type="primary"
        ghost
        onClick={() => {
          Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            content: lang.confirmResend,
            onOk () {
              onResend(
                staff?.connections[0]?.staffId,
                staff?.connections[0]?.originalIdentity,
              );
            },
            onCancel () {
              console.log('Cancel');
            },
          });
        }}
      >
        {lang.resendInvitation}
      </Button>
    );
  };

  const routes = [
    {
      path: '/mysalon',
      breadcrumbName: lang.mySalon,
    },
    {
      path: '/staff-member',
      breadcrumbName: lang.staffMember,
    },
    {
      path: '',
      breadcrumbName: staff?.connections[0]?.data?.name || staff?.name,
    },
  ];

  useEffect(() => {
    handleSetSearchParams({ tab }, true);
  }, []);

  return (
    <PageLayout
      className="staff-detail-page"
      ghost={false}
      title={lang.staffProfile}
      routes={routes}
      onBack={() => window.history.back()}
    >
      <Card style={{ marginBottom: 24, minHeight: 356 }}>
        <Tabs
          defaultActiveKey={tab}
          activeKey={tab}
          tabPosition="top"
          onChange={(key) => handleSetSearchParams({ tab: key })}
        >
          <TabPane tab={lang.personalInfo} key="personal-info">
            <Spin spinning={loading}>
              <div className="profile-dashboard">
                <div className="profile-content">
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
                    <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
                      {/* Remove staff  */}
                      {
                        canRemoveStaff && renderRemoveStaffButton()
                      }
                      {/* Canceled Invitation */}
                      {cancelInvitationBtn.includes(staffStatus) && renderCancelInvitationButton()}
                      {/* Resend invitation */}
                      {resendInvitationBtn.includes(staffStatus) && renderResendInvitationButton()}
                      {renderEditProfileButton()}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={4} style={{ textAlign: 'center' }}>
                      {!staff?.connections[0]?.data?.avatarLink ? (
                        <Avatar
                          src={_.get(staff, 'avatar')}
                          icon={!_.get(staff, 'avatar') && <UserOutlined />}
                          shape="circle"
                          size={84}
                          style={{ marginBottom: 10 }}
                        />
                      ) : (
                        <Avatar
                          src={staff?.connections[0]?.data?.avatarLink}
                          shape="circle"
                          size={84}
                          style={{ marginBottom: 10 }}
                        />
                      )}
                      <div style={{ fontWeight: 'bold' }}>
                        {staff?.connections[0]?.data?.username ||
                          staff?.username || staff?.connections[0]?.originalIdentity}
                      </div>
                    </Col>
                    <Col span={20}>
                      <Row
                        className="gutter-row"
                        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                        justify="start"
                      >
                        <Col span={10}>
                          <span className="title">{lang.fullName}: </span>
                          <span className="content">
                            {staff?.connections[0]?.data?.name || staff?.name}
                          </span>
                        </Col>
                        <Col span={14}>
                          <span className="title">{lang.phonetic}: </span>
                          <span className="content">
                            {staff?.connections[0]?.data?.phonetic || '-'}
                          </span>
                        </Col>
                      </Row>

                      <Row
                        className="gutter-row"
                        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                        justify="start"
                      >
                        <Col span={10}>
                          <span className="title">{lang.gender}: </span>
                          <span className="content">
                            {staff?.connections[0]?.data?.gender === 0 ? lang.male : lang.female}{' '}
                          </span>
                        </Col>
                        <Col span={14}>
                          <span className="title">{lang.personalPhoneNumber}: </span>
                          <span className="content">
                            {/* NLSB 4390 v1.12: Add some field when invite new staff: avatar, username,  */}
                            {/* use username to check and avoid applying logic  getValueFromRandomInfor to old data */}
                            {staff?.connections[0]?.data?.phoneNumber || (!staff?.username ? staff?.phoneNumber : getValueFromRandomInfor(staff?.phoneNumber, PRE_RANDOM_NUM))}
                          </span>
                        </Col>
                      </Row>

                      <Row
                        className="gutter-row"
                        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                        justify="start"
                      >
                        <Col span={10}>
                          <span className="title">{lang.email}: </span>
                          <span className="content">
                            {staff?.connections[0]?.data?.email || (!staff?.username ? staff?.email : getValueFromRandomInfor(staff?.email, PRE_RANDOM_NUM))}
                          </span>
                        </Col>
                        <Col span={14}>
                          <span className="title">{lang.birthday}: </span>
                          <span className="content">
                            {staff?.connections[0]?.data?.birthday || '-'}
                          </span>
                        </Col>
                      </Row>
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="start">
                        <Col span={10}>
                          <span className="title">
                            {lang.status}:{' '}
                          </span>
                          <span className="content">
                            <Tag color={STAFF_STATUS[staffStatus]?.color}>
                              {lang[STAFF_STATUS[staffStatus]?.langKey]}
                            </Tag>
                          </span>
                        </Col>
                        <Col span={14}>
                          <span className="title">{lang.externalParties}: </span>
                          <span className="content">
                            {(staff?.thirdParties?.length > 0 && isConnectedBM && staffStatus === 1) && lang.beautyMerit}{/* connected BM && joined Salon */}
                            {(staff?.thirdParties?.length > 0 && isConnectedCS && staffStatus === 1) && lang.comingSoon}{/* connected CS && joined Salon */}
                            {(staff?.thirdParties?.length === 0 && staffStatus === 1 && !isConnectedBM) && '-'} {/* not connected CS && joined salon */}
                            {staffStatus !== 1 && '-'} {/* not connected 3rd parties && not joined salon */}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </Spin>
          </TabPane>
          {!isHiddenFeature &&
            <TabPane tab={lang.services} key="services">
              <Spin spinning={loading}>
                <div className="profile-dashboard-services">
                  <Row className="service-select-list">
                    <Col className="gutter-row" span={16}>
                      <h3>{lang.services}</h3>
                    </Col>
                    <Col className="gutter-row" span={8} style={{ textAlign: 'right' }}>
                      <Button
                        onClick={() => {
                          Modal.confirm({
                            title: lang.confirm,
                            icon: <ExclamationCircleOutlined />,
                            content: lang.addServicesForStaffConfirmContent,
                            okText: lang.sure,
                            cancelText: lang.no,
                            onOk: () => {
                              handleAddServiceToStaff();
                            },
                          });
                        }}
                        type="primary"
                      >
                        {lang.saveAndPublishServices}
                      </Button>
                    </Col>
                  </Row>
                  <Checkbox.Group
                    style={{ width: '100%' }}
                    value={services}
                    // defaultValue={staff?.services && staff?.services.map(item => item.id)}
                    onChange={(checkedValue) => {
                      if (!checkedValue.includes(recommendService)) {
                        setRecommendService(undefined);
                      }
                      setServices(checkedValue);
                    }}
                  >
                    {categories
                      .filter((i) => i.active === true)
                      .sort((a, b) => a.order - b.order)
                      .map((category, index) => {
                        return renderCategories(category.services, category);
                      })}
                  </Checkbox.Group>
                </div>
              </Spin>
            </TabPane>
          }

        </Tabs>
      </Card>

      <Modal
        visible={isStaffUpdateModal}
        title={lang.personalInfo}
        onCancel={() => setIsStaffUpdateModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        maskClosable={false}
      >
        <StaffUpdateForm
          staffDetail={staff}
          onCancel={() => setIsStaffUpdateModal(false)}
          onCallback={(staff) => {
            dispatch(staffDetail({ staffId: id }))
              .then((result) => {
                setStaff(result.data);
              })
              .catch((err) => console.log(err));

            setIsStaffUpdateModal(false);
          }}
        />
      </Modal>
    </PageLayout>
  );
};

export default StaffDetail;
