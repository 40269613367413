import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { useSearchParams } from 'hooks/useCustomSearchParams';

import { Modal, Button, Tabs, Card, Row, Col, Spin, Table, Badge } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import _ from 'lodash';
import moment from 'moment';

import { useDispatch } from 'hooks/useCustomDispatch';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

import { salonDetail } from 'providers/SalonProvider/actions';
import SalonAccountUpdateForm from 'components/Desktop/Salon/SalonAccountUpdateForm';
import SalonBankUpdateForm from 'components/Desktop/Salon/SalonBankUpdateForm';
import ScheduleUpdateForm from 'components/Desktop/Salon/SalonScheduleUpdateForm';
import SalonCancellationPolicyUpdateForm from 'components/Desktop/Salon/SalonCancellationPolicyUpdateForm';
import SalonCloseDateAddForm from 'components/Desktop/Salon/SalonCloseDateAddForm';
import SalonJapanPublicHolidaysAddForm from 'components/Desktop/Salon/SalonJapanPublicHolidaysAddForm';
import PageLayout from 'components/Desktop/Layout/PageLayout';
import { getLanguages } from 'utils/lang';
import environment from 'environment';

import './styles.less';
import SalonAcceptanceTimeForm from 'components/Desktop/Salon/SalonAcceptanceTimeForm';
import SalonEmailSetting from 'components/Desktop/Salon/SalonEmailSetting';
import SalonConnectThirdParties from 'components/Desktop/Salon/SalonConnectThirdPartiesSchedule';
import OutsideBookingsModal from 'components/Desktop/Booking/outside-booking.modal';
import { getOutsideBookingsBySalon } from 'providers/BookingProvider/actions';
import BookingDetailModal from 'components/Desktop/Booking/booking-detail.modal';
import { SALON_HIDDEN_TYPES } from 'utils/SharedSalonEnv';

const { TabPane } = Tabs;

const SalonAccount = () => {
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const dispatch = useDispatch();

  const [isSalonSchedulEditModal, setIsSalonSchedulEditModal] = useState(false);
  const [isSalonCloseDateAddModal, setIsSalonCloseDateAddModal] = useState(false);
  const [isSalonJapanPublicHolidaysAdd, setIsSalonJapanPublicHolidaysAdd] = useState(false);
  const [outsideBookingsModal, setOutsideBookingsModal] = useState(null);
  const [bookingDetailModal, setBookingDetailModal] = useState(null);
  const [loading, setLoading] = useState(false);

  const [salon, setSalon] = useState();
  const sharedType = useSelector(state => state?.salon?.salon?.salonType);
  const isHiddenTypes = SALON_HIDDEN_TYPES.includes(sharedType);
  const [user, setUser] = useState();

  // <URLSearchParams>
  const [searchParamsValues, handleSetSearchParams] = useSearchParams();
  const tabDefault = isHiddenTypes ? 'banking-information' : 'profile';
  const { tab = tabDefault } = searchParamsValues;
  // </URLSearchParams>
  // Salon not setting profile info

  const needUpdateProfileInfo = salon && (!salon?.logoUrl || !salon?.location?.building || !salon?.description || salon?.properties?.length === 0 || salon?.photos?.length === 0);
  const needUpdateBankInfo = salon && (!salon?.bankInfo);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    (async function () {
      setLoading(true);
      const token = await getAccessTokenSilently({ audience: environment.api.auth0Audience });
      const user = jwtDecode(token);
      setUser(user);
      dispatch(salonDetail({
        salonId: _.get(user, '[https://user_metadata].salon_id'),
      })).then((result) => {
        setLoading(false);
        setSalon(result.data);
      });
    })();

    handleSetSearchParams({ tab }, true);
  }, []);

  const renderCell = (item) => {
    return item.map(i => {
      if (i.startAt !== i.endAt) {
        return (
          <span key={i}>{`${moment(i.startAt).format('HH:mm')} 〜 ${moment(i.endAt).format('HH:mm')}`}</span>
        );
      } else {

      }
    });
  };

  const workingTimesColumns = [
    {
      title: lang.monday,
      dataIndex: 'monday',
      width: '10%',
      fixed: 'left',
      render: (item) => renderCell(item),
    },
    {
      title: lang.tuesday,
      dataIndex: 'tuesday',
      width: '10%',
      fixed: 'left',
      render: (item) => renderCell(item),
    },
    {
      title: lang.wednesday,
      dataIndex: 'wednesday',
      width: '10%',
      fixed: 'left',
      render: (item) => renderCell(item),
    },
    {
      title: lang.thursday,
      dataIndex: 'thursday',
      width: '10%',
      fixed: 'left',
      render: (item) => renderCell(item),
    },
    {
      title: lang.friday,
      dataIndex: 'friday',
      width: '10%',
      fixed: 'left',
      render: (item) => renderCell(item),
    },
    {
      title: <span className="day-of-week sat">{lang.saturday}</span>,
      dataIndex: 'saturday',
      width: '10%',
      fixed: 'left',
      render: (item) => renderCell(item),
    },
    {
      title: <span className="day-of-week sun">{lang.sunday}</span>,
      dataIndex: 'sunday',
      width: '10%',
      fixed: 'left',
      render: (item) => renderCell(item),
    },
  ];

  const routes = [
    {
      path: '/mysalon',
      breadcrumbName: lang.mySalon,
    },
    {
      path: '/settings',
      breadcrumbName: lang.settings,
    },
  ];

  return (
    <PageLayout ghost={false} title={lang.salonAccount} routes={routes}>
      <div className="salon-account-page">
        <Spin spinning={loading}>
          <Card style={{ marginBottom: 24 }}>
            <Tabs
              defaultActiveKey={tabDefault}
              activeKey={tab}
              tabPosition="top"
              onChange={(key) => {
                handleSetSearchParams({ tab: key });
              }}
              style={{ marginTop: -20 }}
              destroyInactiveTabPane
            >
              {
                !isHiddenTypes &&
                <TabPane tab={<Badge offset={[8, 5]} dot={needUpdateProfileInfo}>{lang.profile}</Badge>} key="profile">
                  <div>
                    {salon && (
                      <SalonAccountUpdateForm
                        salon={salon}
                        onCallback={(result) => {
                          dispatch(
                            salonDetail({
                              salonId: _.get(user, '[https://user_metadata].salon_id'),
                            }),
                          ).then((result) => {
                            setSalon(result.data);
                          });
                        }}
                      />
                    )}
                  </div>
                </TabPane>
              }
              <TabPane tab={<Badge offset={[8, 5]} dot={needUpdateBankInfo}>{ lang.bankingInfomation}</Badge>} key="banking-information">
                {salon && (
                  <div style={{ marginTop: 15 }}>
                    <SalonBankUpdateForm
                      salon={salon}
                      onCallback={(salon) => {
                        dispatch(
                          salonDetail({
                            salonId: _.get(user, '[https://user_metadata].salon_id'),
                          }),
                        ).then((result) => {
                          setSalon(result.data);
                        });
                      }}
                    />
                  </div>
                )}
              </TabPane>
              <TabPane tab={lang.schedule} key="schedule">
                <Row
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  justify="space-between"
                  style={{ marginTop: 10 }}
                >
                  <Col span={12}>
                    <h2>{lang.workingTime}</h2>
                  </Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    <Button
                      type="primary"
                      icon={<EditOutlined />}
                      onClick={() => setIsSalonSchedulEditModal(true)}
                      disabled={salon?.lockSchedule} // Can not Edit schedule when salon allow sync Schedule to BM
                    >
                      {lang.editWorkingTime}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Table
                      columns={workingTimesColumns}
                      dataSource={[
                        {
                          monday: _.get(salon, 'schedules', []).filter(
                            (item) => item.dayOfWeek === 1,
                          ),
                          tuesday: _.get(salon, 'schedules', []).filter(
                            (item) => item.dayOfWeek === 2,
                          ),
                          wednesday: _.get(salon, 'schedules', []).filter(
                            (item) => item.dayOfWeek === 3,
                          ),
                          thursday: _.get(salon, 'schedules', []).filter(
                            (item) => item.dayOfWeek === 4,
                          ),
                          friday: _.get(salon, 'schedules', []).filter(
                            (item) => item.dayOfWeek === 5,
                          ),
                          saturday: _.get(salon, 'schedules', []).filter(
                            (item) => item.dayOfWeek === 6,
                          ),
                          sunday: _.get(salon, 'schedules', []).filter(
                            (item) => item.dayOfWeek === 0,
                          ),
                        },
                      ]}
                      pagination={false}
                    />
                  </Col>
                </Row>
                <Row>
                  {salon && (
                    <SalonAcceptanceTimeForm
                      salon={salon}
                      onCallback={(result) => {
                        dispatch(
                          salonDetail({
                            salonId: _.get(user, '[https://user_metadata].salon_id'),
                          }),
                        ).then((result) => {
                          setSalon(result.data);
                        });
                      }}
                    />
                  )}
                </Row>

              </TabPane>
              {
                !isHiddenTypes &&
                <TabPane tab={lang.cancellationPolicy} key="cancellation-policy">
                  {salon && (
                    <SalonCancellationPolicyUpdateForm
                      salon={salon}
                      onCallback={(result) => {
                        dispatch(
                          salonDetail({
                            salonId: _.get(user, '[https://user_metadata].salon_id'),
                          }),
                        ).then((result) => {
                          setSalon(result.data);
                        });
                      }}
                    />
                  )}
                </TabPane>
              }
              <TabPane tab={lang.emailAddress} key="email">
                {salon && (
                  <SalonEmailSetting
                    salon={salon}
                    onCallback={(result) => {
                      dispatch(
                        salonDetail({
                          salonId: _.get(user, '[https://user_metadata].salon_id'),
                        }),
                      ).then((result) => {
                        setSalon(result.data);
                      });
                    }}
                  />
                )}
              </TabPane>

              <TabPane tab={lang.salonBMSchedule} key="salonBM">
                {salon && (
                  <SalonConnectThirdParties
                    salon={salon}
                    setSalon={setSalon}
                    onCallback={(result) => {
                      dispatch(
                        salonDetail({
                          salonId: _.get(user, '[https://user_metadata].salon_id'),
                        }),
                      ).then((result) => {
                        setSalon(result.data);
                      });
                    }}
                  />
                )}
              </TabPane>

            </Tabs>
          </Card>
        </Spin>
      </div>

      <Modal
        visible={isSalonSchedulEditModal}
        title={lang.updateWorkingTime}
        onCancel={() => {
          setIsSalonSchedulEditModal(false);
          dispatch(
            salonDetail({
              salonId: _.get(user, '[https://user_metadata].salon_id'),
            }),
          ).then((result) => {
            setSalon(result.data);
          });
        }}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        maskClosable={false}
        closable={false}
        keyboard={false}
      >
        <ScheduleUpdateForm
          salon={salon}
          onCancel={() => setIsSalonSchedulEditModal(false)}
          onCallback={async () => {
            const salonUpdated = await dispatch(
              salonDetail({
                salonId: _.get(user, '[https://user_metadata].salon_id'),
              }),
            );
            setSalon(salonUpdated.data);
            // show list booking of of salon working time
            const bookingsOutside = await dispatch(getOutsideBookingsBySalon());
            const { data } = bookingsOutside;
            if (data?.length !== 0) {
              setOutsideBookingsModal({ data });
            } else {
              setIsSalonSchedulEditModal(false);
            }
          }}
        />
      </Modal>

      <Modal
        visible={isSalonCloseDateAddModal}
        title={lang.addNewClosedDate}
        onCancel={() => setIsSalonCloseDateAddModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <SalonCloseDateAddForm onCancel={() => setIsSalonCloseDateAddModal(false)} />
      </Modal>

      <Modal
        visible={isSalonJapanPublicHolidaysAdd}
        title={lang.addJapanPublicHolidays}
        onCancel={() => setIsSalonJapanPublicHolidaysAdd(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <SalonJapanPublicHolidaysAddForm onCancel={() => setIsSalonJapanPublicHolidaysAdd(false)} />
      </Modal>
      <OutsideBookingsModal
        outsideBookingsModal={outsideBookingsModal}
        closeOutsideBookingsModal={() => {
          setOutsideBookingsModal(null);
          setIsSalonSchedulEditModal(false);
        }}
        setBookingDetailModal={setBookingDetailModal}
      />
      <BookingDetailModal
        bookingId={bookingDetailModal}
        closeBookingDetailModal={() => setBookingDetailModal(null)}
        setOutsideBookingsModal={setOutsideBookingsModal}
        onCallbackBookingDetailModal={() => {
          setLoading(true);
          dispatch(getOutsideBookingsBySalon())
            .then((result) => {
              const { data } = result;
              if (data.length !== 0) {
                setOutsideBookingsModal({ data });
              }
              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
            });
        }}
      />
    </PageLayout>
  );
};

export default SalonAccount;
