import {
  SALON_DETAIL,
  SALON_ACCOUNT_UPDATE,
  SALON_BANK_UPDATE,
  SALON_GET_BANK,
  SALON_GET_BANK_BRANCH,
  SALON_SCHEDULE_UPDATE,
  SALON_GET_PROPERTIES,
  SALON_SETTINGS_GET,
  SALON_CANCELLATION_POLICY_UPDATE,
  SALON_GET_ALL_SURVEY,
  SALON_SURVEY_DETAIL,
  SALON_UPDATE_SURVEY,
  SALON_SURVEY_RESULT,
  GET_LIST_SALON_SALE_REPORT,
  GET_LIST_STAFF_SALE_REPORT_BY_SALON,
  GET_LIST_BOOKING_SALON_SALE_REPORT,
  SALON_GET_ALL_AREAS,
  TOGGLE_THIRD_PARTY,
  CHANGE_SALON_EMAIL,
} from './types';
import request from 'utils/request';
import { put, takeLeading, call } from 'redux-saga/effects';

import {
  salonDetailSuccess,
  salonDetailFail,
  salonAccountUpdateFail,
  salonAccountUpdateSuccess,
  salonGetPropertiesFail,
  salonGetPropertiesSuccess,
  salonBankUpdateSuccess,
  salonBankUpdateFail,
  salonGetBankSuccess,
  salonGetBankFail,
  salonGetBankBranchSuccess,
  salonGetBankBranchFail,
  salonScheduleUpdateFail,
  salonScheduleUpdateSuccess,
  salonSettingsGetFail,
  salonSettingsGetSuccess,
  salonCancellationPolicyUpdateFail,
  salonCancellationPolicyUpdateSuccess,
  salonGetAllSurveySuccess,
  salonGetAllSurveyFail,
  salonSurveyDetailSuccess,
  salonSurveyDetailFail,
  salonUpdateSurveySuccess,
  salonUpdateSurveyFail,
  salonSurveyResultSuccess,
  salonSurveyResultFail,
  getListSalonSaleReportSuccess,
  getListSalonSaleReportFail,
  getListStaffSaleReportBySalonSuccess,
  getListStaffSaleReportBySalonFail,
  getListBookingSalonSaleReportSuccess,
  getListBookingSalonSaleReportFail,
  salonGetAllAreasSuccess,
  salonGetAllAreasFail,
  toggle3rdPartySuccess,
  toggle3rdPartyFail,
  changeSalonEmailSuccess,
  changeSalonEmailFail,
} from './actions';

import environment from 'environment';
import { message as Alert } from 'antd';
import BookingStatusHelper from '../../utils/bookingStatusHelper';
import { checkAndRedirectSalonType } from 'utils/SharedSalonEnv';
import { mappingS3Domain } from 'utils/mappingS3Domain';

function * salonDetail (action) {
  const { salonId, token } = action.payload;

  try {
    const salon = yield call(request, `${environment.api.salonDetail}/${salonId}`, {}, 'GET', token);
    checkAndRedirectSalonType(salon?.data?.salonType);
    yield put(salonDetailSuccess({ ...salon, data: { ...salon.data, latestCallAPITime: Number(new Date()) } }, action.meta));
  } catch (error) {
    yield put(salonDetailFail(error, action.meta));
  }
}

function * salonAccountUpdate (action) {
  try {
    const { businessName, phoneNumber, logoUrl, salonId, area, postCode, prefecture, cityOrTown, building, address, latLng, isPrivate, station, photos, propertyIds, description, listEmail, token, invoice } = action.payload;
    yield call(request, `${environment.api.updateAccountSalon}/${salonId}`, {
      businessName,
      phoneNumber,
      logoUrl,
      location: {
        postCode,
        prefecture,
        cityOrTown,
        address,
        building,
        latLng,
        area,
        station,
        isPrivate,
      },
      description,
      photos,
      propertyIds,
      listEmail,
      invoice,
    }, 'PATCH', token);

    yield put(salonAccountUpdateSuccess({}, action.meta));
    Alert.success('設定を変更しました');
  } catch (error) {
    let msg = '';

    if (error.data.length > 0) {
      msg = '';
      error.data.forEach((item) => {
        msg = `${msg} ${item.message}. `;
      });
      Alert.error(msg);
    } else {
      msg = error.message;
      Alert.error(error.message);
    }
    yield put(salonAccountUpdateFail(msg, action.meta));
  }
}

function * salonGetProperties (action) {
  try {
    const { token } = action.payload;
    const result = yield call(request, environment.api.salonGetProperties, {}, 'GET', token);
    // mapping s3 domain
    result.data = result.data.map(p => {
      return {
        ...p,
        icon: mappingS3Domain(p.icon),
      };
    });

    yield put(salonGetPropertiesSuccess(result, action.meta));
  } catch (error) {
    console.log('errors: ', error);
    yield put(salonGetPropertiesFail(error, action.meta));
  }
}

function * salonBankUpdate (action) {
  try {
    const { bankId, branchId, accountType, accountNumber, accountName, salonId, photo, token } = action.payload;
    const salon = yield call(request, `${environment.api.salonBankUpdate}/${salonId}/banks`,
      {
        bankId,
        branchId,
        accountType,
        accountNumber,
        accountName,
        photo,
      }, 'PATCH', token);

    yield put(salonBankUpdateSuccess(salon, action.meta));

    Alert.success('設定を変更しました');
  } catch (error) {
    yield put(salonBankUpdateFail(error, action.meta));
  }
}

function * salonGetBank (action) {
  try {
    const banks = yield call(request, environment.api.salonGetBank, {}, 'GET', action.payload.token);
    yield put(salonGetBankSuccess(banks, action.meta));
  } catch (error) {
    console.log('errors: ', error);
    yield put(salonGetBankFail(error, action.meta));
  }
}

function * salonGetBankBranch (action) {
  const { bankId, token } = action.payload;

  try {
    const branches = yield call(request, `${environment.api.salonGetBankBranch}/${bankId}/branches`, {}, 'GET', token);
    yield put(salonGetBankBranchSuccess(branches, action.meta));
  } catch (error) {
    console.log('errors: ', error);
    yield put(salonGetBankBranchFail(error, action.meta));
  }
}

function * salonScheduleUpdate (action) {
  try {
    const { timezone, schedules, salonId, token } = action.payload;
    const salon = yield call(request, `${environment.api.updateScheduleSalon}/${salonId}/schedule`,
      {
        timezone,
        schedules,
      }, 'PUT', token);

    yield put(salonScheduleUpdateSuccess(salon, action.meta));

    Alert.success('設定が完了しました。');
  } catch (error) {
    yield put(salonScheduleUpdateFail(error, action.meta));
  }
}

function * salonSettingsGet (action) {
  const { token } = action.payload;

  try {
    const settings = yield call(request, environment.api.salonSettingsGet, {}, 'GET', token);
    yield put(salonSettingsGetSuccess(settings, action.meta));
  } catch (error) {
    yield put(salonSettingsGetFail(error, action.meta));
  }
}

function * salonCancellationPolicyUpdate (action) {
  try {
    const { salonId, code, token } = action.payload;
    const policy = yield call(request, `${environment.api.salonCancellationPolicyUpdate}/${salonId}/cancellationPolicy/${code}`,
      {}, 'PUT', token);

    yield put(salonCancellationPolicyUpdateSuccess(policy, action.meta));
    Alert.success('設定を変更しました');
  } catch (error) {
    yield put(salonCancellationPolicyUpdateFail(error, action.meta));
  }
}

function * salonGetAllSurvey (action) {
  try {
    const { token } = action.payload;
    const allSurvey = yield call(request, environment.api.salonGetAllSurvey, {}, 'GET', token);

    yield put(salonGetAllSurveySuccess(allSurvey, action.meta));
  } catch (error) {
    yield put(salonGetAllSurveyFail(error, action.meta));
  }
}

function * salonSurveyDetail (action) {
  try {
    const { surveyId, token } = action.payload;
    const surveyDetail = yield call(request, `${environment.api.salonSurveyDetail}/${surveyId}`, {}, 'GET', token);
    yield put(salonSurveyDetailSuccess(surveyDetail, action.meta));
  } catch (error) {
    yield put(salonSurveyDetailFail(error, action.meta));
  }
}

function * salonUpdateSurvey (action) {
  try {
    const { salonId, surveyId, token, questions } = action.payload;
    const surveyUpdate = yield call(request, `${environment.api.salonUpdateSurvey}/${salonId}/surveys/${surveyId}/results`,
      {
        questions,
      },
      'PUT', token);
    yield put(salonUpdateSurveySuccess(surveyUpdate, action.meta));
    Alert.success('設定を変更しました');
  } catch (error) {
    yield put(salonUpdateSurveyFail(error, action.meta));
  }
}

function * salonSurveyResult (action) {
  try {
    const { salonId, surveyId, token } = action.payload;
    const surveyResult = yield call(request, `${environment.api.salonUpdateSurvey}/${salonId}/surveys/${surveyId}/results`, {}, 'GET', token);
    yield put(salonSurveyResultSuccess(surveyResult, action.meta));
  } catch (error) {
    yield put(salonSurveyResultFail(error, action.meta));
  }
}

function * getListSalonSaleReport (action) {
  try {
    const { salonId, from, to, token } = action.payload;
    const { data } = yield call(
      request,
      `${environment.api.saleReportsSalons}/${salonId}`,
      { from, to },
      'GET',
      token,
    );
    yield put(getListSalonSaleReportSuccess(data, action.meta));
  } catch (error) {
    yield put(getListSalonSaleReportFail(error, action.meta));
  }
}

function * getListStaffSaleReportBySalon (action) {
  try {
    const { salonId, page, limit, from, to, token } = action.payload;
    const { data } = yield call(
      request,
      `${environment.api.saleReportsSalons}/${salonId}/staff`,
      { page, limit, from, to },
      'GET',
      token,
    );

    data.data.sort((a, b) => b.totalTreatmentSales - a.totalTreatmentSales);

    yield put(getListStaffSaleReportBySalonSuccess(data, action.meta));
  } catch (error) {
    yield put(getListStaffSaleReportBySalonFail(error, action.meta));
  }
}

function * getListBookingSalonSaleReport (action) {
  try {
    const { salonId, page, limit, from, to, token } = action.payload;
    const { data } = yield call(
      request,
      `${environment.api.saleReportsSalons}/${salonId}/bookings`,
      { page, limit, from, to, targetSystemStatus: 'CANCELED_RESERVATION,DONE,NOVISIT' },
      'GET',
      token,
    );
    BookingStatusHelper.FormatSaleReportBookingStatus(data);
    yield put(getListBookingSalonSaleReportSuccess(data, action.meta));
  } catch (error) {
    yield put(getListBookingSalonSaleReportFail(error, action.meta));
  }
}

function * salonGetAllAreas (action) {
  try {
    const areas = yield call(request, environment.api.salonGetAllAreas, {}, 'GET', action.payload.token);
    yield put(salonGetAllAreasSuccess(areas, action.meta));
  } catch (error) {
    console.log('errors: ', error);
    yield put(salonGetAllAreasFail(error, action.meta));
  }
}
function * toggle3rdParty (action) {
  const { tokenText } = action.payload;
  try {
    const data = yield call(request, environment.api.toggleThirdPartyConnection,
      {
        code: action.payload.code,
        token: !tokenText ? undefined : tokenText,
      }, 'POST', action.payload.token);
    yield put(toggle3rdPartySuccess(data, action.meta));
    Alert.success('設定を変更しました');
  } catch (error) {
    console.log('errors: ', error);
    Alert.error('正しい認証トークンを入力してください');
    yield put(toggle3rdPartyFail(error, action.meta));
  }
}
function * changeSalonEmail (action) {
  const { salonId, token } = action?.payload;
  try {
    const data = yield call(request,
      `${environment.api.requestChangeEmail}/${salonId}/request-change-email`,
      {
        email: action.payload.newEmail,
      },
      'POST', token);
    yield put(changeSalonEmailSuccess(data, action.meta));
  } catch (error) {
    yield put(changeSalonEmailFail(error, action.meta));
    const errContent = error?.data[0]?.message;
    Alert.error(errContent);
  }
}

export default function * watchSalonList () {
  yield takeLeading(SALON_DETAIL, salonDetail);
  yield takeLeading(SALON_ACCOUNT_UPDATE, salonAccountUpdate);
  yield takeLeading(SALON_GET_PROPERTIES, salonGetProperties);
  yield takeLeading(SALON_BANK_UPDATE, salonBankUpdate);
  yield takeLeading(SALON_GET_BANK, salonGetBank);
  yield takeLeading(SALON_GET_BANK_BRANCH, salonGetBankBranch);
  yield takeLeading(SALON_SCHEDULE_UPDATE, salonScheduleUpdate);
  yield takeLeading(SALON_SETTINGS_GET, salonSettingsGet);
  yield takeLeading(SALON_CANCELLATION_POLICY_UPDATE, salonCancellationPolicyUpdate);
  yield takeLeading(SALON_GET_ALL_SURVEY, salonGetAllSurvey);
  yield takeLeading(SALON_SURVEY_DETAIL, salonSurveyDetail);
  yield takeLeading(SALON_UPDATE_SURVEY, salonUpdateSurvey);
  yield takeLeading(SALON_SURVEY_RESULT, salonSurveyResult);
  yield takeLeading(GET_LIST_SALON_SALE_REPORT, getListSalonSaleReport);
  yield takeLeading(GET_LIST_STAFF_SALE_REPORT_BY_SALON, getListStaffSaleReportBySalon);
  yield takeLeading(GET_LIST_BOOKING_SALON_SALE_REPORT, getListBookingSalonSaleReport);
  yield takeLeading(SALON_GET_ALL_AREAS, salonGetAllAreas);
  yield takeLeading(TOGGLE_THIRD_PARTY, toggle3rdParty);
  yield takeLeading(CHANGE_SALON_EMAIL, changeSalonEmail);
}
